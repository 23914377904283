import { reactive, toRefs } from 'vue';
import { useSdk } from './use-mycure';
import { useFacilities } from './use-facilities';

const QUEUES_SERVICE_NAME = 'queues';
const QUEUE_ITEMS_SERVICE_NAME = 'queue-items';
const queuesState = reactive({
  queues: [],
  defaultQueue: null,
});

export function useQueues () {
  const sdk = useSdk();
  const { getActiveFacility } = useFacilities();

  async function listItems (opts) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    opts = Object.assign({ type: 'doctor' });

    const query = {
      type: opts.type,
      organization: facilityId,
    };

    const result = await sdk?.list(QUEUES_SERVICE_NAME, query);
    const queues = result?.data || [];
    queuesState.queues = queues;
    queuesState.defaultQueue = queues[0];
  }

  return {
    ...toRefs(queuesState),
    listItems,
  };
}

// TODO: add createItem (must be independent from encounter creation)
// TODO: add updateItem - update queue item status

const queueItemsState = reactive({
  queueItems: [],
});

export function useQueueItems () {
  const sdk = useSdk();
  const { getActiveFacility } = useFacilities();

  async function createItem (data) {
    // const activeFacility = await getActiveFacility();
    // const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const payload = {
      // organization: facilityId,
      subject: data.patientId,
      group: data.encounterId,
      queue: data.queueId,
      subjectType: data.subjectType || 'medical-patient',
    };

    const result = await sdk?.create(QUEUE_ITEMS_SERVICE_NAME, payload);
    queueItemsState.queueItems.unshift(result);
  }

  async function listItems (opts) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      organization: facilityId,
      // status: 'active',
      order: {
        $exists: true,
      },
      $sort: {
        createdAt: -1,
      },
    };
    const result = await sdk?.list(QUEUE_ITEMS_SERVICE_NAME, query);
    queueItemsState.queueItems = result.data || [];
  }

  async function findItem (opts) {
    const query = {
      group: opts.encounterId,
      finishedAt: {
        $exists: false,
      },
    };

    const result = await sdk?.get(QUEUE_ITEMS_SERVICE_NAME, query);
    return result;
  }

  async function updateItem (id, data) {
    const result = await sdk?.update(QUEUE_ITEMS_SERVICE_NAME, id, data);
    return result;
  }

  return {
    ...toRefs(queueItemsState),
    createItem,
    listItems,
    findItem,
    updateItem,
  };
}
